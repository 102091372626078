import { Button, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Post, PostType } from "../../types/postType";
import { FullTextEditDiv, StyledBlogAddBody } from "./style";
import { postTypeAllGet } from "../../API/postTypeApi";
import { postGet, postUpdate } from "../../API/postApi";
import Loading from "../HomePage/components/loading/Loading";
import FullTextEditor from "../components/FullTextEditor/FullTextEditor";

const BlogUpdatePage: FC = () => {
  const history = useNavigate();

  const params = useParams();

  const [html, setHtml] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [types, setTypes] = useState<string[]>([]);
  const [allTypes, setAllTypes] = useState<PostType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Post | null>(null);

  useEffect(() => {
    (async function anyNameFunction() {
      await getPostTypes();
      await getPost();
    })();
  }, []);

  const getPostTypes = async () => {
    setLoading(true);
    const result = await postTypeAllGet();
    if (result) {
      setAllTypes(result);
    }
  };

  const getPost = async () => {
    const result = await postGet(params?.id ? params?.id : "");
    if (result) {
      setData(result.post);
      setHtml(result.post.context);
      setTitle(result.post.title);
    }
    setLoading(false);
  };

  const updateBlog = () =>
    postUpdate({
      _id: data ? data._id : "",
      typeIds: data?.typeIds,
      title: title,
      context: html,
      createAt: data?.createAt,
    })
      .then((r) => {
        history(`/blogs/null`);
      })
      .catch((error) => {
        console.log(error);
      });

  const getExistTypes = () =>
    allTypes.filter((type) => data?.typeIds?.includes(type._id));

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <StyledBlogAddBody>
          <h2>updatePage</h2>
          <h3>Title:</h3>
          <TextField
            id="outlined-basic"
            variant="outlined"
            inputProps={{ "aria-label": "Without label" }}
            value={title}
            onChange={(v) => setTitle(v.target.value)}
          />
          <h3>Types:</h3>
          {getExistTypes().map((item) => {
            return <span>{item.type}</span>;
          })}
          <h3>Context:</h3>
          <FullTextEditDiv>
            <FullTextEditor
              html={html}
              setFullText={(e: any) => {
                setHtml(e);
              }}
            />
          </FullTextEditDiv>
          <Button onClick={() => updateBlog()} variant="contained">
            Update
          </Button>
        </StyledBlogAddBody>
      )}
    </>
  );
};
export default BlogUpdatePage;

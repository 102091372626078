import {
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PostType } from "../../types/postType";
import FullTextEditor from "../components/FullTextEditor/FullTextEditor";
import { FullTextEditDiv, StyledBlogAddBody } from "./style";
import { postTypeAllGet } from "../../API/postTypeApi";
import { postAdd } from "../../API/postApi";

const BlogAddPage: FC = () => {
  const history = useNavigate();

  const [html, setHtml] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [types, setTypes] = useState<string[]>([]);
  const [allTypes, setAllTypes] = useState<PostType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async function anyNameFunction() {
      await getPostTypes();
    })();
  }, []);

  const getPostTypes = async () => {
    setLoading(true);
    const result = await postTypeAllGet();
    if (result) {
      setAllTypes(result);
    }
    setLoading(false);
  };

  const addNewBlog = () =>
    postAdd({
      _id: "",
      typeIds: types.map((type) => {
        const typeBody = allTypes.filter((ele) => ele.type == type);
        return typeBody[0]._id;
      }),
      title: title,
      context: html,
      createAt: new Date().valueOf(),
    })
      .then((r) => {
        history(`/blogs/null`);
      })
      .catch((error) => {
        console.log(error);
      });

  return (
    <>
      {loading ? (
        <p>Loading</p>
      ) : (
        <StyledBlogAddBody>
          <h2>addPage</h2>
          <h3>Title:</h3>
          <TextField
            id="outlined-basic"
            variant="outlined"
            inputProps={{ "aria-label": "Without label" }}
            onChange={(v) => setTitle(v.target.value)}
          />
          <h3>Types:</h3>
          <FormControl sx={{ m: 0, width: 300, mt: 3 }}>
            <Select
              multiple
              displayEmpty
              value={types}
              onChange={(v) =>
                setTypes(
                  typeof v.target.value == "string"
                    ? v.target.value.split(",")
                    : v.target.value
                )
              }
              input={<OutlinedInput />}
              renderValue={(selected) => selected.join(", ")}
              inputProps={{ "aria-label": "Without label" }}
            >
              {allTypes.map((postType: PostType) => (
                <MenuItem key={postType._id} value={postType.type}>
                  <Checkbox checked={types.indexOf(postType.type) > -1} />
                  <ListItemText primary={postType.type} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <h3>Context:</h3>
          <FullTextEditDiv>
            <FullTextEditor
              html={html}
              setFullText={(e) => {
                setHtml(e);
              }}
            />
          </FullTextEditDiv>
          <Button onClick={() => addNewBlog()} variant="contained">
            Submit
          </Button>
        </StyledBlogAddBody>
      )}
    </>
  );
};
export default BlogAddPage;

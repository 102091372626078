import { Button } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { Observable, Subject, Subscription } from "rxjs";
import { StyledRXJS } from "./style";
import {
  cancelObservable,
  createObservable,
  createSubject,
  subscribeObservable,
} from "../../utils/Rxjs/observables";

const RxjsPage: FC = () => {
  //Observable
  /*const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [time, setTime] = useState<String>("");

  useEffect(() => {
    //const subject = new Subject();
  });

  const observable = createObservable((subscriber) => {
    setInterval(() => {
      subscriber.next(new Date());
    }, 1000);
  });

  const subscribe = () => {
    const _subscription = subscribeObservable(observable, (v) =>
      setTime((v as Date).toString())
    );
    setSubscription(_subscription);
  };

  const unsubscribe = () => {
    if (subscription) {
      cancelObservable(subscription);
    }
  };

  const completeF = () => {
    if (subscription) {
      subscription.unsubscribe();
    }
  };*/
  //

  const [subject, setSubject] = useState<Subject<unknown> | null>(null);
  const [id, setId] = useState<any>(null);
  const [time, setTime] = useState<String>("");

  useEffect(() => {
    console.log(id);
  }, [id]);

  useEffect(() => {
    const _subject = createSubject(
      (v: any) => {
        const _id = setInterval(() => {
          setTime(new Date().toString());
        }, 1000);
        console.log(_id);
        setId(_id);
      },
      () => {
        console.log(id);
        if (id) {
          console.log(id);
          clearInterval(id);
        }
      }
    );
    setSubject(_subject);
  }, []);

  const subscribe = () => {
    if (subject) {
      subject.next("");
    }
  };

  const unsubscribe = () => {
    if (subject) {
      console.log("Sd");
      subject.complete();
      clearInterval(id);
    }
  };

  return (
    <StyledRXJS>
      <p>{time}</p>
      <Button onClick={() => subscribe()}>Start</Button>
      <Button onClick={() => unsubscribe()}>End</Button>
      {/**
      <Button onClick={() => subscribe()}>Complete </Button>*/}
    </StyledRXJS>
  );
};
export default RxjsPage;

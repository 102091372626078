import { FC } from "react";
import HomePage from "../../HomePage";
import "react-vertical-timeline-component/style.min.css";
import ThreeDModalPage from "../../../3DModalPage/3DModalPage";
import RxjsPage from "../../../RxjsPage/RxjsPage";

const SubRxjsModal: FC = () => {
  const getContext = () => {
    return <RxjsPage />;
  };

  return (
    <>
      <HomePage state={3} context={getContext()} />
    </>
  );
};
export default SubRxjsModal;

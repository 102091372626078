import { FC } from "react";
import HomePage from "../../HomePage";
import "react-vertical-timeline-component/style.min.css";
import ThreeDModalPage from "../../../3DModalPage/3DModalPage";

const SubThreeDModal: FC = () => {
  const getContext = () => {
    return <ThreeDModalPage />;
  };

  return (
    <>
      <HomePage state={2} context={getContext()} />
    </>
  );
};
export default SubThreeDModal;

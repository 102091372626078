import { DragAttributes } from "./values/DragControlAttribute";
import { OrbitAttributes } from "./values/OrbitControlAttribute";

export const getAttributes = (typeName: string) => {
  switch (typeName) {
    case "ORBIT_CONTROL":
      return OrbitAttributes;
    case "DRAG_CONTROL":
      return DragAttributes;
    default:
      return {};
  }
};

import { FC, useEffect, useState } from "react";
import {
  Styled3DModal,
  Styled3DModalSideBar,
  Styled3DModalView,
} from "./style";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CONTROLS_TYPES } from "../../types/threeJsTypes/enumType";
import ThreeDControlsView from "./Views/ControlsView/ThreeDControlsView";
import ThreeDModalOne from "./components/3DmodalOne/3DModal";

enum SIDE_TYPE {
  NULL = "NULL",
  CONTROLS = "CONTROLS",
}

const sideMenuList = [
  {
    menuName: "Controls",
    menuType: SIDE_TYPE.CONTROLS,
    list: [
      {
        name: "Orbit control",
        type: CONTROLS_TYPES.ORBIT_CONTROL,
      },
      {
        name: "Drag control",
        type: CONTROLS_TYPES.DRAG_CONTROL,
      },
      /*{
        name: "First Person control",
        type: CONTROLS_TYPES.FIRST_PERSON_CONTROL,
      },
      {
        name: "Pointer Lock Control",
        type: CONTROLS_TYPES.POINTER_LOCK_CONTROL,
      },*/
    ],
  },
];

const ThreeDModalPage: FC = () => {
  const [sideType, setSideType] = useState<SIDE_TYPE>(SIDE_TYPE.NULL);
  const [canvasType, setCanvasType] = useState<CONTROLS_TYPES | null>(null);

  useEffect(() => {
    console.log(canvasType);
  }, [canvasType]);

  const changeSideType = (value: SIDE_TYPE) => {
    if (value != sideType) {
      setSideType(value);
    } else {
      setSideType(SIDE_TYPE.NULL);
    }
  };

  const getList = () => {
    return sideMenuList.map((menu) => {
      return (
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <h3>THREE MODALS</h3>
            </ListSubheader>
          }
        >
          <ListItemButton onClick={() => changeSideType(menu.menuType)}>
            <ListItemText primary={menu.menuName} />
            {sideType == menu.menuType ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={sideType == menu.menuType} timeout="auto" unmountOnExit>
            {menu.list.map((menuEle) => {
              return (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={(v) => setCanvasType(menuEle.type)}
                >
                  <ListItemText primary={menuEle.name} />
                </ListItemButton>
              );
            })}
          </Collapse>
        </List>
      );
    });
  };

  const getView = () => {
    switch (sideType) {
      case SIDE_TYPE.CONTROLS:
        return <ThreeDControlsView type={canvasType as CONTROLS_TYPES} />;
      default:
        return <></>;
      //<ThreeDModalOne />;
    }
  };

  return (
    <Styled3DModal>
      <Styled3DModalSideBar>{getList()}</Styled3DModalSideBar>
      <Styled3DModalView>{getView()}</Styled3DModalView>
    </Styled3DModal>
  );
};
export default ThreeDModalPage;

import { OrbitControls, Preload, useGLTF } from "@react-three/drei";
import { Suspense } from "react";
import CanvasLoader from "../../subHome/components/HomePageComponentOne/component/CanvasLoader";

const Earth = () => {
  const earth = useGLTF("./earth.gltf");

  return (
    <primitive object={earth.scene} scale={3} position-y={0} rotation-y={0} />
  );
};

const EarthCanvas = () => {
  return (
    <>
      <OrbitControls enableZoom={false} autoRotate />
      <ambientLight intensity={0.1} />
      <directionalLight position={[3, 2, 1]} intensity={2} />
      <Suspense fallback={<CanvasLoader />}>
        <Earth />
        <Preload all />
      </Suspense>
    </>
  );
};

export default EarthCanvas;

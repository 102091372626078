import { Navigate, Outlet, RouteObject } from "react-router-dom";
import SubAboutMeComponent from "../pages/HomePage/components/subAboutMe/SubAboutMeComponent";
import SubBlogsComponent from "../pages/HomePage/components/subBlogs/SubBlogsPageComponent";
import SubHomeComponent from "../pages/HomePage/components/subHome/SubHomePageComponent";
import BlogPage from "../pages/BlogPage/BlogPage";
import BlogAddPage from "../pages/BlogAddPage/BlogAddPage";
import BlogUpdatePage from "../pages/BlogUpdatePage/BlogUpdatePage";
import ThreeDModalPage from "../pages/3DModalPage/3DModalPage";
import SubThreeDModal from "../pages/HomePage/components/sub3DModal/SubThreeDModal";
import RxjsPage from "../pages/RxjsPage/RxjsPage";
import SubRxjsModal from "../pages/HomePage/components/subRxjsModal/subRxjsModal";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <SubHomeComponent />,
      },
      { path: "aboutMe", element: <SubAboutMeComponent /> },
      {
        path: "blogs",
        element: <Outlet />,
        children: [
          {
            path: ":typeId",
            element: <SubBlogsComponent />,
          },
        ],
      },
      {
        path: "3DModals",
        element: <Outlet />,
        children: [
          {
            path: ":modalId",
            element: <SubThreeDModal />,
          },
        ],
      },
      { path: "RxJs", element: <SubRxjsModal /> },
      { path: "", element: <SubHomeComponent /> },
    ],
  },
  {
    path: "/blog",
    element: <Outlet />,
    children: [
      {
        path: ":id",
        element: <BlogPage />,
      },
    ],
  },
  {
    path: "/addBlog",
    element: <BlogAddPage />,
  },
  {
    path: "/updateBlog",
    element: <Outlet />,
    children: [
      {
        path: ":id",
        element: <BlogUpdatePage />,
      },
    ],
  },
];

import { CONTROLS_TYPES } from "../../types/threeJsTypes/enumType";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { DragControls } from "three/examples/jsm/controls/DragControls.js";
import { CameraType, ControlType } from "../../types/threeJsTypes/types";
import {
  ControlAttributesType,
  DragAttributesType,
  OrbitAttributesType,
} from "../../types/threeJsTypes/controlsTypes";

export const get3DControls = (
  type: CONTROLS_TYPES,
  camera: CameraType,
  renderer: THREE.WebGLRenderer,
  objects: THREE.Mesh<THREE.BoxGeometry, THREE.MeshBasicMaterial>[]
) => {
  switch (type) {
    case CONTROLS_TYPES.ORBIT_CONTROL:
      return new OrbitControls(camera, renderer.domElement);
    case CONTROLS_TYPES.DRAG_CONTROL:
      return new DragControls([...objects], camera, renderer.domElement);
  }
};

export const getControlMove = (
  render: () => void,
  controls: ControlType,
  attributes: ControlAttributesType
) => {
  let requestAnimationId: number | undefined;

  const animate = () => {
    requestAnimationId = requestAnimationFrame(animate);
    if (controls) {
      const typeName = (controls as any).constructor.name;
      switch (typeName) {
        case "OrbitControls":
          (controls as OrbitControls).update();
          setOrbitControlAttributes(
            controls as OrbitControls,
            attributes as OrbitAttributesType
          );
          break;
        case "DragControls":
          setDragControlAttributes(
            controls as DragControls,
            attributes as DragAttributesType
          );
          /*controls.addEventListener("dragstart", function (event) {
            // 拖拽开始时的处理逻辑
            console.log("Drag Start");
          });

          controls.addEventListener("drag", function (event) {
            // 拖拽过程中的处理逻辑
            console.log("Dragging");
          });

          controls.addEventListener("dragend", function (event) {
            // 拖拽结束时的处理逻辑
            console.log("Drag End");
          });*/
          break;
        default:
          break;
      }
    }
    render();
  };

  const startAnimation = () => {
    if (!requestAnimationId) {
      animate();
    }
  };

  const stopAnimation = () => {
    if (requestAnimationId) {
      cancelAnimationFrame(requestAnimationId);
    }
    requestAnimationId = undefined;
  };
  return {
    start: startAnimation,
    stop: stopAnimation,
  };
};

export const setOrbitControlAttributes = (
  controls: OrbitControls,
  attributes: OrbitAttributesType
) => {
  controls.autoRotateSpeed = attributes.autoRotateSpeed;
  controls.autoRotate = attributes.autoRotate;
  controls.enableDamping = attributes.enableDamping;
  controls.enableZoom = attributes.enableZoom;
};

export const setDragControlAttributes = (
  controls: DragControls,
  attributes: DragAttributesType
) => {
  controls.enabled = attributes.enabled;
};

import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  StyledBlogBody,
  StyledBlogContextBody,
  StyledBlogContextHeader,
  StyledBlogRelateBlogsBody,
  StyledBlogTypesDiv,
} from "./style";
import { StyledHeader } from "../HomePage/style";
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
} from "@mui/material";
import logo from "../../img/logo.jpg";
import Loading from "../HomePage/components/loading/Loading";
import { Post, PostType } from "../../types/postType";
import { postTypeAllGet } from "../../API/postTypeApi";
import { postGet } from "../../API/postApi";
import { EDIT_STATE } from "../../common/const";

const BlogPage: FC = () => {
  const subPages = ["", "blogs/null", "aboutMe"];

  const history = useNavigate();

  const [value] = useState(0);
  const [allTypes, setAllTypes] = useState<PostType[]>([]);
  const [data, setData] = useState<Post | null>(null);
  const [relatedPostIds, setRelatedPostIds] = useState<
    {
      _id: string;
      title: string;
    }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const params = useParams();

  useEffect(() => {
    (async function anyNameFunction() {
      await getPostTypes();
      await getPost();
    })();
  }, [params?.id]);

  const getPostTypes = async () => {
    setLoading(true);
    const result = await postTypeAllGet();
    if (result) {
      setAllTypes(result);
    }
  };

  const getPost = async () => {
    const result = await postGet(params?.id ? params?.id : "");
    if (result) {
      setData(result.post);
      setRelatedPostIds(result.relatedPostIds);
    }
    setLoading(false);
  };

  const getExistTypes = () =>
    allTypes.filter((type) => data?.typeIds?.includes(type._id));

  const toBlogTypePage = (id: string) => history(`/blogs/${id}`);

  const getContext = () => {
    return loading || !data ? (
      <Loading />
    ) : (
      <>
        <StyledBlogContextHeader>
          <h1>{data.title}</h1>
          <span>{new Date(Number(data.createAt)).toDateString()}</span>
        </StyledBlogContextHeader>
        <StyledBlogTypesDiv>
          <p>Types:</p>
          {getExistTypes().map((item) => {
            return (
              <span onClick={() => toBlogTypePage(item._id)}>{item.type}</span>
            );
          })}
        </StyledBlogTypesDiv>
        <StyledBlogContextBody
          dangerouslySetInnerHTML={{
            __html: data.context ? data.context : "",
          }}
        ></StyledBlogContextBody>
        <StyledBlogRelateBlogsBody>
          <h4>Related Blogs</h4>
          {relatedPostIds.map((relatedPost) => (
            <p>
              <a onClick={() => history(`/blog/${relatedPost._id}`)}>
                {relatedPost.title}
              </a>
            </p>
          ))}
        </StyledBlogRelateBlogsBody>
        <></>
      </>
    );
  };

  return (
    <StyledBlogBody>
      <StyledHeader>
        <img src={logo} />
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => history(`/${subPages[newValue]}`)}
        >
          <BottomNavigationAction label="Home" />
          <BottomNavigationAction label="Blogs" />
          <BottomNavigationAction label="About me" />
        </BottomNavigation>
      </StyledHeader>
      {getContext()}
      {EDIT_STATE ? (
        <Button
          variant="outlined"
          onClick={() => history(`/updateBlog/${params?.id ? params?.id : ""}`)}
        >
          Update
        </Button>
      ) : (
        <></>
      )}
    </StyledBlogBody>
  );
};
export default BlogPage;

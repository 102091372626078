import styled from "styled-components";

export const Styled3DModal = styled.div`
  padding-left: 50px;
  display: flex;
  p {
    color: white;
  }
`;

export const Styled3DModalSideBar = styled.div`
  width: 250px;
  p,
  h3 {
    color: white;
  }
  .MuiList-padding {
    background-color: #a136be;
  }
  #nested-list-subheader {
    background-color: #a136be;
  }
`;

export const Styled3DModalView = styled.div`
  padding-left: 20px;
  h3 {
    color: #a136be;
  }
`;

import Axios from "axios";
import { API_ADDRESS } from "../common/const";
import { Post } from "../types/postType";

const basicURL = API_ADDRESS;

export const postsAllGet = async (
  typeId: string,
  title: string,
  page: number,
  pageSize: number
): Promise<{
  result: Post[];
  count: number;
} | null> => {
  const endpoint = `${basicURL}postsAllGet?typeId=${typeId}&title=${title}&page=${page}&pageSize=${pageSize}`;
  return Axios.get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return null;
    });
};

export const postGet = async (
  postId: string
): Promise<{
  post: Post;
  relatedPostIds: {
    _id: string;
    title: string;
  }[];
} | null> => {
  const endpoint = `${basicURL}postGet?postId=${postId}`;
  return Axios.get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return null;
    });
};

export const postUpdate = async (post: Post): Promise<number | null> => {
  const endpoint = basicURL + "postUpdate";
  return Axios.put(endpoint, { postBody: post })
    .then((response) => {
      return response.status;
    })
    .catch(() => {
      return null;
    });
};

export const postAdd = async (post: Post): Promise<number | null> => {
  const endpoint = basicURL + "postInsert";
  return Axios.post(endpoint, { postBody: post })
    .then((response) => {
      return response.status;
    })
    .catch(() => {
      return null;
    });
};

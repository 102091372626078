import * as THREE from "three";
import { CameraType } from "../../types/threeJsTypes/types";

export const getScene = (
  camera: CameraType,
  objs: THREE.Mesh<THREE.BoxGeometry, THREE.MeshBasicMaterial>[],
  addAxes: boolean
) => {
  const scene = new THREE.Scene();
  scene.add(camera);
  objs.forEach((obj) => {
    scene.add(obj);
  });
  if (addAxes) {
    const axesHelper = new THREE.AxesHelper(5);
    scene.add(axesHelper);
  }
  return scene;
};

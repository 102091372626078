import * as THREE from "three";

export const getObject = (
  width: number,
  height: number,
  depth: number,
  color: string
) => {
  const geometry = new THREE.BoxGeometry(width, height, depth);

  const material = new THREE.MeshBasicMaterial({ color: color });

  const mesh = new THREE.Mesh(geometry, material);

  return mesh;
};

import styled from "styled-components";

export const StyledViewButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .MuiInputBase-input {
    padding: 5px;
  }
  div {
    display: flex;
    height: 32px;
    margin-right: 10px;
  }
  p {
    margin-right: 10px;
  }
`;

import Axios from "axios";
import { API_ADDRESS } from "../common/const";
import { Post, PostType } from "../types/postType";

const basicURL = API_ADDRESS;

export const postTypeAllGet = async (): Promise<PostType[] | null> => {
  const endpoint = `${basicURL}postTypesAllGet`;
  return Axios.get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return null;
    });
};
export const postTypeAdd = async (
  postType: PostType
): Promise<number | null> => {
  const endpoint = basicURL + "postTypeInsert";
  return Axios.post(endpoint, { postTypeBody: postType })
    .then((response) => {
      return response.status;
    })
    .catch(() => {
      return null;
    });
};

import { FC, useEffect, useState } from "react";
import { Button, Input } from "@mui/material";
import { ControlAttributesType } from "../../../types/threeJsTypes/controlsTypes";
import { StyledViewButtonsContainer } from "./style";

interface ViewButtonsProps {
  attributes: ControlAttributesType | undefined;
  onChange: (attributes: ControlAttributesType) => void;
}

interface ObjectAttributeType {
  [key: string]: any; // Add an index signature that accepts string keys
}

const ViewButtons: FC<ViewButtonsProps> = (props) => {
  const [attributes, setAttributes] = useState<
    ControlAttributesType | undefined
  >(undefined);
  const [allButtons, setAllButtons] = useState<(JSX.Element | undefined)[]>([]);

  useEffect(() => {
    setAttributes(props.attributes);
    setAllButtons(getAllButtons(props.attributes));
  }, [props.attributes]);

  useEffect(() => {
    setAllButtons(getAllButtons(attributes));
    if (attributes) {
      props.onChange(attributes);
    }
  }, [attributes]);

  const getAllButtons = (attributes: ControlAttributesType | undefined) => {
    const buttons: {
      name: string;
      value: boolean | number | string;
    }[] = [];
    if (attributes !== undefined) {
      const objs = attributes as ObjectAttributeType;
      Object.keys(objs).forEach((key) => {
        buttons.push({
          name: key,
          value: objs[key],
        });
      });
    }
    return buttons.map((button) =>
      getButton(button.name, button.value, (v) => {
        const newAttributes = JSON.parse(
          JSON.stringify(attributes)
        ) as ObjectAttributeType;
        (newAttributes as ObjectAttributeType)[button.name] = v;
        setAttributes(newAttributes as ControlAttributesType);
      })
    );
  };

  const getButton = (
    name: string,
    value: boolean | number | string,
    Fn: (value: boolean | number | string) => void
  ) => {
    switch (typeof value) {
      case "boolean":
        return (
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => Fn(!value)}
            >
              {name}
            </Button>
          </div>
        );
      case "number":
        return (
          <div>
            <p>{name}</p>
            <Input
              type="number"
              defaultValue={value}
              sx={{
                border: "1px solid #a136be", // 设置背景颜色为蓝色
                backgroundColor: "white",
                padding: "0px",
                "& input": {
                  color: "black", // 设置文本颜色为白色
                  padding: "0px",
                },
              }}
              onChange={(v) => Fn(parseFloat(v.target.value))}
            />
          </div>
        );
    }
  };

  return <StyledViewButtonsContainer>{allButtons}</StyledViewButtonsContainer>;
};
export default ViewButtons;

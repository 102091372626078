import * as THREE from "three";
import { CAMERA_TYPES } from "../../types/threeJsTypes/enumType";

export const getCamera = (type: CAMERA_TYPES, value: number[]) => {
  switch (type) {
    case CAMERA_TYPES.ORTHOGRAPHIC:
      return new THREE.OrthographicCamera(...value);
    case CAMERA_TYPES.PERSPECTIVE:
      return new THREE.PerspectiveCamera(...value);
  }
};

import { FC, useEffect, useState } from "react";
import * as THREE from "three";
import {
  CAMERA_TYPES,
  CONTROLS_TYPES,
} from "../../../../types/threeJsTypes/enumType";
import { getCamera } from "../../../../utils/3DMethods/getCamera";
import { getObject } from "../../../../utils/3DMethods/getObject";
import { getScene } from "../../../../utils/3DMethods/getScene";
import {
  get3DControls,
  getControlMove,
} from "../../../../utils/3DMethods/getControls";
import { getAttributes } from "../../Attributes/getAttribute";
import ViewButtons from "../../component/ViewButtons";
import { ControlAttributesType } from "../../../../types/threeJsTypes/controlsTypes";
import { StyledThreeDControlsViewContainer } from "./style";
import { ControlType } from "../../../../types/threeJsTypes/types";

interface ThreeDControlsViewProps {
  type: CONTROLS_TYPES;
}

const ThreeDControlsView: FC<ThreeDControlsViewProps> = (props) => {
  const [attributes, setAttributes] = useState<
    ControlAttributesType | undefined
  >(undefined);
  const [animation, setAnimation] = useState<{
    start: () => void;
    stop: () => void;
  } | null>(null);

  const [animationFn, setAnimationFn] = useState<{
    start: () => void;
    stop: () => void;
    render: THREE.WebGLRenderer;
  } | null>(null);
  const [cameraPosition, setCameraPosition] = useState<any>(null);

  const size = {
    width: 800,
    height: 600,
  };

  const camera = getCamera(CAMERA_TYPES.ORTHOGRAPHIC, [-5, 5, 5, -5, 1, 1000]);

  const object = getObject(1, 1, 1, "red");

  const scene = getScene(camera, [object], false);

  const renderFn = () => {
    const renderer = new THREE.WebGLRenderer({
      canvas: document.getElementById("3dscreen") as HTMLCanvasElement,
    });
    renderer.setSize(size.width, size.height);
    return renderer;
  };

  const renderAnimationWithControl = () => {
    const renderer = renderFn();
    const controls = get3DControls(props.type, camera, renderer, [object]);
    renderer.render(scene, camera);
    const animationFn = animationFunction(renderer, controls);
    return animationFn;
  };

  const setPosition = (type: string) => {
    if (type == "DRAG_CONTROL") {
      camera.position.z = 5;
      camera.lookAt(scene.position);
    } else {
      if (cameraPosition) {
        const x = cameraPosition.x as number;
        const y = cameraPosition.y as number;
        const z = cameraPosition.z as number;
        camera.position.set(x, y, z);
      } else {
        camera.position.set(6, 6, 100);
      }
    }
  };

  const animationFunction = (
    renderer: THREE.WebGLRenderer,
    controls: ControlType
  ) => {
    const stopAnimation = () => {
      //stop previous animation
      if (animation) {
        animation.stop();
      }
    };

    const startAnimation = () => {
      //start the next animation
      if (attributes) {
        const _animation = getControlMove(
          () => {
            setCameraPosition(camera.position);
            renderer.render(scene, camera);
          },
          controls,
          attributes
        );
        _animation.start();
        //save animation
        setAnimation(animation);
      }
    };

    return {
      start: startAnimation,
      stop: stopAnimation,
      render: renderer,
    };
  };

  const getControlAttributes = (type: string) =>
    setAttributes(getAttributes(type));

  const restartAnimation = () => {
    const _animationFn = renderAnimationWithControl();
    setAnimationFn(_animationFn);
    _animationFn.start();
  };

  useEffect(() => {
    setPosition(props.type);
    getControlAttributes(props.type);
    if (animationFn !== null) {
      animationFn.stop();
    }
    restartAnimation();
  }, [props.type]);

  useEffect(() => {
    if (animationFn) {
      setPosition(props.type);
      animationFn.stop();
      restartAnimation();
    }
  }, [attributes]);

  return (
    <StyledThreeDControlsViewContainer>
      <h3>{props.type ? props.type.toString() : ""}</h3>
      <canvas id="3dscreen"></canvas>
      <ViewButtons
        attributes={attributes}
        onChange={(attributes) => setAttributes(attributes)}
      />
    </StyledThreeDControlsViewContainer>
  );
};
export default ThreeDControlsView;

export enum CONTROLS_TYPES {
  DRAG_CONTROL = "DRAG_CONTROL",
  FIRST_PERSON_CONTROL = "FIRST_PERSON_CONTROL",
  ORBIT_CONTROL = "ORBIT_CONTROL",
  POINTER_LOCK_CONTROL = "POINTER_LOCK_CONTROL",
}

export enum CAMERA_TYPES {
  ORTHOGRAPHIC = "ORTHOGRAPHIC",
  PERSPECTIVE = "PERSPECTIVE",
}
